import { CheckIcon } from "@heroicons/react/24/outline";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Button } from "../components/button";
import Layout from "../components/layout";
import Section from "../components/section";
import CTADemo from "../components/sections/cta-demo";
import SEO from "../components/seo";

const SolutionsPage = () => {
    return (
        <Layout>
            <section id="hero" className="relative bg-slate-900/70 after:bg-slate-900/60 after:block after:h-full after:absolute after:right-0 after:left-0 after:top-0 after:content-['']">
                <div className="z-0 absolute left-0 bottom-0 right-0 top-0 overflow-hidden">
                    <StaticImage alt="Solutions" src="../assets/solutions-bg-2.png" className="w-full h-full" imgClassName="object-cover object-bottom w-full h-full" layout="fullWidth" />
                </div>

                <div className="z-10 relative flex h-full items-center justify-center text-center px-8 py-36 sm:py-48 md:py-60 xl:px-0 container max-w-7xl mx-auto">
                    <div className="max-w-sm sm:max-w-md md:max-w-xl text-white">
                        <div className="space-y-2">
                            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold">
                                Solutions
                            </h1>
                            <p className="text-lg md:text-xl font-normal">
                                No more crafting a question and hoping for a meaningful answer. Coeus is built on a secure framework with proven industry leading AI capabilities.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <Section className="bg-gray-50">
                <div className="space-y-10 lg:mx-auto lg:grid lg:grid-cols-2 lg:items-center lg:gap-24">
                    <StaticImage
                        src="../assets/solutions-1.png"
                        className="shadow-md rounded-xl overflow-hidden"
                        imgClassName="rounded-xl"
                        quality={100}
                        placeholder="blurred"
                        loading="lazy"
                        layout="fullWidth"
                        alt=""
                    />
                    <div className="">
                        <h1 className="text-center text-3xl md:text-4xl font-bold leading-none">The Solution to your Needs</h1>
                        <p className="text-base mt-2 md:text-lg font-light leading-snug">There are plenty of tools, products, and best practices around how to build the ideal customer experience, but at a basic level it's about helping customers reach their goals in the fewest number of steps. The best solution needs to encompass the values of: </p>
                        <div className="mt-6 grid grid-cols-1  md:grid-cols-2 gap-6">
                            <div className="">
                                <h2 className="text-lg font-semibold leading-tight">Automation</h2>
                                <p className="text-base md:text-lg font-light">Automating both simple and more complex customer inquiries across any channel</p>
                            </div>
                            <div className="">
                                <h2 className="text-lg font-semibold leading-tight">Comprehension</h2>
                                <p className="text-base md:text-lg font-light">Understanding complex language as well as conversational digressions</p>
                            </div>
                            <div className="">
                                <h2 className="text-lg font-semibold leading-tight">Learning</h2>
                                <p className="text-base md:text-lg font-light">Natural Language Processing to continuously learn from and improve customer conversations</p>
                            </div>
                            <div className="">
                                <h2 className="text-lg font-semibold leading-tight">AI Integration</h2>
                                <p className="text-base md:text-lg font-light">Integrating conversational Al into existing applications, content, and data</p>
                            </div>
                            <div className="">
                                <h2 className="text-lg font-semibold leading-tight">Security</h2>
                                <p className="text-base md:text-lg font-light">Ensuring security and data ownership while maintaining ongoing flexibility </p>
                            </div>
                            <div className="">
                                <h2 className="text-lg font-semibold leading-tight">Optimization</h2>
                                <p className="text-base md:text-lg font-light">Ensuring the assistant is agnostic to as many environments and software apps as possible</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>

            <div className="w-full max-w-7xl pb-[72px] mx-auto mt-0 overflow-x-scroll sm:overflow-x-hidden">
                <div className="space-y-2 pt-[72px] pb-[18px]">
                    <h1 className="text-3xl md:text-4xl text-center font-bold">Industries</h1>
                    <p className="text-base md:text-lg font-light text-center">
                        The industries comparison chart below outlines the key features that each package includes across the different industries.
                    </p>
                </div>
                <div className="w-full px-8 xl:px-0  min-w-[720px]">
                    <table className="table-fixed w-full">
                        <thead>
                            <tr>
                                <th></th>
                                <th align="center" className="px-4 py-2.5">
                                    <div className="flex flex-col">
                                        <span>Healthcare</span>
                                        {/* <span className="font-light">14 days only</span> */}
                                    </div>
                                </th>
                                <th align="center" className="px-4 py-2.5">
                                    <div className="flex flex-col">
                                        <span>Retail</span>
                                        {/* <span className="font-light">$100 / mo</span> */}
                                    </div>
                                </th>
                                <th align="center" className="px-4 py-2.5">
                                    <div className="flex flex-col">
                                        <span>Transportation</span>
                                        {/* <span className="font-light">$999 / mo</span> */}
                                    </div>
                                </th>
                                <th align="center" className="px-4 py-2.5">
                                    <div className="flex flex-col">
                                        <span>Disaster Response</span>
                                        {/* <span className="font-light">Get a quote</span> */}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y border-borderLight border-b border-t">
                            <tr className="bg-gray-50">
                                <td className="text-left font-bold px-4 py-2.5">All Packages</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="font-light">
                                <td className="text-left px-4 py-2.5">Natural Language Conversation</td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                            </tr>
                            <tr className="font-light">
                                <td className="text-left px-4 py-2.5">Agent Escalation (Slack, email)</td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                            </tr>
                            <tr className="font-light">
                                <td align="center" className="text-left px-4 py-2.5">
                                    Realistic 3D Avatars (Soul Machines)
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                            </tr>
                            <tr className="font-light">
                                <td align="center" className="text-left px-4 py-2.5">
                                    Voice Chatbot (via Twilio)
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                            </tr>

                            <tr className="bg-gray-50">
                                <td className="text-left font-bold px-4 py-2.5">Enterprise Package</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="font-light">
                                <td align="center" className="text-left px-4 py-2.5">
                                    Hybird, Cloud-native Deployments
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                            </tr>
                            <tr className="font-light">
                                <td align="center" className="text-left px-4 py-2.5">
                                    Agent Escalation (custom)
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                            </tr>
                            <tr className="font-light">
                                <td align="center" className="text-left px-4 py-2.5">
                                    HIPAA-Ready Deployment
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                            </tr>
                            <tr className="font-light">
                                <td align="center" className="text-left px-4 py-2.5">
                                    Patient Appointment Scheduler
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                </td>
                            </tr>
                            <tr className="font-light">
                                <td align="center" className="text-left px-4 py-2.5">
                                    E-commerce UI
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                </td>
                            </tr>
                            <tr className="font-light">
                                <td align="center" className="text-left px-4 py-2.5">
                                    Entity Location Tracking
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                </td>
                            </tr>
                            <tr className="font-light">
                                <td align="center" className="text-left px-4 py-2.5">
                                    Live Weather Map
                                </td>
                                <td align="center" className="px-4 py-2.5 mx-auto">
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                </td>
                                <td align="center" className="px-4 py-2.5">
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <Section className="bg-gray-50">
                <div className="space-y-10 lg:mx-auto lg:grid lg:grid-cols-2 lg:items-center lg:gap-24">

                    <StaticImage
                        src="../assets/dfs-zoe-headshot.png"
                        className="shadow-md rounded-xl overflow-hidden"
                        imgClassName="rounded-xl"
                        placeholder="blurred"
                        quality={100}
                        loading="lazy"
                        layout="fullWidth"
                        alt=""
                    />

                    <div className="">
                        <h1 className="text-3xl md:text-4xl font-bold leading-none">Soul Machines Integration</h1>
                        <h2 className="text-lg md:text-xl font-bold leading-none">*Additional cost</h2>
                        <p className="mt-2 text-base md:text-lg font-light leading-snug">The marriage of Soul Machines and Coeus provides our customers a way to quickly implement a more personal, more intelligent chatbot to their customers. In a world where a strong digital presence is a necessity, we bring you a solution for customer-to-virtual-assistant conversations, enhanced customer-to-agent conversations, or a mix of where the agent has all information from the bot conversation. </p>
                        <p className="mt-4 text-base md:text-lg font-light leading-snug">The addition of the digital person from Soul Machines makes the interaction between the bot and the customer feel more like a conversation with a live person and allows the interaction to evolve based on the behavior of the customer.</p>
                        <Button className="mt-8" onClick={() => window.coeus.intent("What is Soul Machines?")}>Learn more</Button>
                    </div>
                </div>
            </Section>

            <CTADemo />
        </Layout>
    );
};

export default SolutionsPage;

export const Head = () => {
    return (
        <SEO
            title="Solutions"
            description="No more crafting a question and hoping for a meaningful answer. Coeus is built on a secure framework with proven industry leading AI capabilities."
        >
        </SEO>
    );
};
